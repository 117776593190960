import cx from 'classnames';
import React, { useState } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import PortableImage from '../components/images/HowItWorksPortableImage';
import FixedImage from '../components/images/HowItWorksFixedImage';

import styles from './services.module.scss';
import Enquire from '../components/pages/home/Enquire';

const ServicesPage = props => {
  const [currentTab, setCurrentTab] = useState('fixed');

  return (
    <Layout location={props.location} className={styles.root}>
      <SEO title="服務流程" />
      <section className={styles.lead}>
        <h1>嶄新營運模式</h1>
        <p>
          除了傳統的自存倉以外，我們更設有新式的流動倉，價格更為相宜，以迎合不同客戶的需求。不論您有任何儲存需要，我們都能為您提供最貼心的服務。
        </p>
      </section>

      <div className={styles.tabs}>
        <div
          role="button"
          className={cx(styles.tab, { [styles.active]: currentTab === 'fixed' })}
          onClick={() => setCurrentTab('fixed')}
        >
          自存倉
        </div>
        <div
          role="button"
          className={cx(styles.tab, { [styles.active]: currentTab === 'portable' })}
          onClick={() => setCurrentTab('portable')}
        >
          流動倉
        </div>
        <div className={styles.highlight} />
      </div>
      <section className={styles.content}>
        {currentTab === 'fixed' && (
          <div className={styles.wrapper}>
            <div className={styles.image}>
              <FixedImage />
            </div>
            <div className={styles.text}>
              <h2 className={styles.title}>自存倉服務流程</h2>
              <p>
                RoomyR提供安全、可靠的儲物空間，為解決客戶家中或辦公室內堆存物件的存放問題，從而提升都市人的空間質素，自存倉用戶完成睇倉及簡易文件手續後，只要配備輕觸式智能卡便可24小時自由進出迷你倉進行物品提存。
              </p>
              <div className={styles.steps}>
                <div className={styles.step}>
                  <div className={styles.number}>1</div>
                  <div>
                    <header>安排</header>
                    <div>選擇地點、迷你倉尺寸、預約睇倉及安排入倉事宜。</div>
                  </div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>2</div>
                  <div>
                    <header>儲存</header>
                    <div>閣下可向客戶服務員選購紙箱及鎖頭，自行把物品存進迷你倉。</div>
                  </div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>3</div>
                  <div>
                    <header>提取</header>
                    <div>閤下收到 Access Card 後，無須預約即可24小時隨時隨地進出迷你倉。</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentTab === 'portable' && (
          <div className={styles.wrapper}>
            <div className={styles.image}>
              <PortableImage />
            </div>
            <div className={styles.text}>
              <h2 className={styles.title}>流動倉服務流程</h2>
              <p>
                對比於自存倉，RoomyR流動倉價錢更經濟而且更富彈性，流動倉通常尺數較細小並配有活動輪，當物品存放好後，我們會有專人把流動倉移到主倉，如客人需要提存物品，流動倉需要在1日前事先預約，適合存倉較小型的物品而且不是常常進出迷你倉的用戶。
              </p>
              <div className={styles.steps}>
                <div className={styles.step}>
                  <div className={styles.number}>1</div>
                  <div>
                    <header>安排</header>
                    <div>與我們的儲存顧問溝通所需要的儲物空間、訂購及安排入倉事宜。</div>
                  </div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>2</div>
                  <div>
                    <header>儲存</header>
                    <div>我們的團隊將確保您的物件委善存放在我們的倉庫。</div>
                  </div>
                </div>
                <div className={styles.step}>
                  <div className={styles.number}>3</div>
                  <div>
                    <header>提取</header>
                    <div>
                      您只需提早一天通過電話、網頁或Whatsapp預約日子，在該日子的任何時間均可自行到存倉地點整理或提取您的物件。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>

      <Enquire />
    </Layout>
  );
};

export default ServicesPage;
